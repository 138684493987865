import { createAction, props } from '@ngrx/store';
import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { Reseller, ResellerRole } from '../../models';
import { SearchParams } from '../../models/objects/search-params';

export const loadRequest = createAction(
  '[Resellers] Load Request',
  props<{ params?: { name?: string; role?: ResellerRole; page?: number } }>(),
);
export const loadSuccess = createAction(
  '[Resellers] Load Success',
  props<{ resellers: Reseller[]; pagination: IPagination }>(),
);
export const loadFailure = createAction(
  '[Resellers] Load Failure',
  props<{ error: any }>(),
);

export const loadDetailsRequest = createAction(
  '[Resellers] Load Details Request',
  props<{ resellerId: number }>(),
);
export const loadDetailsSuccess = createAction(
  '[Resellers] Load Details Success',
  props<{ reseller: Reseller }>(),
);
export const loadDetailsFailure = createAction(
  '[Resellers] Load Details Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Resellers] Create Request',
  props<{ reseller: Omit<Reseller, 'id'>; onSuccess?: () => void }>(),
);
export const createSuccess = createAction(
  '[Resellers] Create Success',
  props<{ reseller: Reseller }>(),
);
export const createFailure = createAction(
  '[Resellers] Create Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Resellers] Update Request',
  props<{ reseller: Reseller; onSuccess?: () => void }>(),
);
export const updateSuccess = createAction(
  '[Resellers] Update Success',
  props<{ reseller: Reseller }>(),
);
export const updateFailure = createAction(
  '[Resellers] Update Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Resellers] Delete Request',
  props<{ id: number }>(),
);
export const deleteSuccess = createAction(
  '[Resellers] Delete Success',
  props<{ id: number }>(),
);
export const deleteFailure = createAction(
  '[Resellers] Delete Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Resellers] Reset State');

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { featureAdapter, State } from './state';

export const selectState = createFeatureSelector<State>('resellers');

export const selectAllItems =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectById = (id: number) =>
  createSelector(selectState, (state) => state.entities[id]);

export const selectError = createSelector(selectState, (state) => state.error);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading,
);

export const selectPagination = createSelector(
  selectState,
  (state) => state.pagination,
);
